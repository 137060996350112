/* eslint-disable no-console */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    _sem: {
      authorization: null
    },

    _pc: {
      
      email: null,
      firstName: null,
      lastName: null,
      address: null,
      city: null,
      state: null,
      zip5: null,
      householdId: null,

      coa: '',
      codPending: false,

      doNotSell: false,

      uHouseholdDemographics: 'allow',
      uHouseholdAffluence: 'allow',
      uResidenceInformation: 'allow',
      uVehicleInformation: 'allow',
      uPoliticalInformation: 'allow',
      uMediaInformation: 'allow',
      uShopperPreferences: 'allow',

      uNumAdults: 'allow',
      uAgeRange: 'allow',
      uPresenceOfChildren: 'block',
      uIncome: 'allow',
      uDiscIncome: 'allow',
      uHomeValue: 'allow',
      uLengthOfResidence: 'allow',
      uVehiclePropensity: 'allow',
      uVoterRegistration: 'allow',
        
      vNumAdults: null,
      vAgeRange_1: null,
      vAgeRange_2: null,
      vPresenceOfChildren: null,
      vIncome: null,
      vDiscIncome: null,
      vHomeValue: null,
      vLengthOfResidence: null,
      vVehiclePropensity_1: null,
      vVehiclePropensity_2: null,
      vVoterRegistration_1: null,
      vVoterRegistration_2: null,

      cArtsAndEntertainment: false,
      cAutomotive: false,
      cBusiness: false,
      cCareers: false,
      cEducation: false,
      cFamilyAndParenting: false,
      cFoodAndDrink: false,
      cHealthAndFitness: false,
      cHomeAndGarden: false,
      cLawGovernmentAndPolitics: false,
      cNews: false,
      cPersonalFinance: false,
      cScience: false,
      cSociety: false,
      cSports: false,
      cTechnologyAndComputing: false,
      cTravel: false,

      cArtsAndCraftsStores: false,
      cBigBoxShoppers: false,
      cRetailVitaminShoppers: false,
      cCoffeeChainConsumers: false,
      cElectronicsStoreShoppers: false,
      cFamilyRestaurantCustomers: false,
      cFastFoodCustomers: false,
      cGroceryChainShoppers: false,
      cHomeImprovementStores: false,
      cKitchenAndBathShoppers: false,
      cRetailClothingStores: false,
      cOfficeSupplyStores: false,
      cRetailFitnessCenterClients: false,
      cRetailPetStoreShoppers: false,
      cRxChainShoppers: false,
      cSportingGoodsStores: false,
      cSportsAttendees: false,
      cTelecomProviders: false
    },

    _cd: null
  },
  
  mutations: {
    SET_SEM(state, payload) {
      state._sem = payload
    },
    SET_PC(state, payload) {
      state._pc = payload
    },
    SET_CD(state, payload) {
      state._cd = payload
    }
  },

  actions: {
    setSem(context, payload) {
      context.commit('SET_SEM', payload)
    },
    setPc(context, payload) {
      context.commit('SET_PC', payload)
    },
    setCd(context, payload) {
      context.commit('SET_CD', payload)
    }
  },

  getters: {
    sem(state) {
      return state._sem
    },
    pc(state) {
      return state._pc;
    },
    cd(state) {
      return state._cd;
    }
  }
})
