import axios from 'axios';

export const apiMixin = {

  data() {
    return {
      vueAppApiUrl: process.env.VUE_APP_API_URL,
      sem: this.$store.getters.sem,
      pc: this.$store.getters.pc
    }
  },

  methods: {

    /***** The following functions do NOT require an Authorization code *****/

    apiSignup(email, firstName, lastName, zip5, token) {
      return (axios({
        method: "post",
        url: this.vueAppApiUrl + "signup",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          "email": email,
          "firstName": firstName,
          "lastName": lastName,
          "zip5": zip5,
          "token": token
        }
      }))
    },

    apiActivate(email, passcode, password, token) {
      return (axios({
        method: "post",
        url: this.vueAppApiUrl + "activate",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          "email": email,
          "passcode": passcode,
          "password": password,
          "token": token
        }
      }))
    },

    apiRequestPwReset(email, token) {
      return (axios({
        method: "post",
        url: this.vueAppApiUrl + "requestpwreset",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          "email": email,
          "token": token
        }
      }))
    },

    apiResetPw(email, passcode, password, token) {
      return (axios({
        method: "post",
        url: this.vueAppApiUrl + "resetpw",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          "email": email,
          "passcode": passcode,
          "password": password,
          "token": token
        }
      }))
    },

    apiLogin(email, password, token) {
      return (axios({
        method: "post",
        url: this.vueAppApiUrl + "login",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          "email": email,
          "password": password,
          "captchaToken": token
        }
      }))
    },

    apiContactUs(form) {
      //console.log("form=%o",form);
      return (axios({
        method: "post",
        url: this.vueAppApiUrl + "contactus",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          "firstName": form.firstName,
          "lastName": form.lastName,
          "email": form.email,
          "phone": form.phone,
          "company": form.company,
          "jobTitle": form.jobTitle,
          "message": form.message
        }
      }))
    },

    /***** The following functions DO require an Authorization code *****/

    apiSaveNameZip() {
      return (axios({
        method: "post",
        url: this.vueAppApiUrl + "api/savenz",
        headers: {
          "Content-Type": "application/json",
          "Authorization": this.sem.authorization
        },
        data: this.pc
      }))
    },

    apiSaveCoa() {
      return (axios({
        method: "post",
        url: this.vueAppApiUrl + "api/savecoa",
        headers: {
          "Content-Type": "application/json",
          "Authorization": this.sem.authorization
        },
        data: this.pc
      }))
    },

    apiSavePersonalInformation() {
      return (axios({
        method: "post",
        url: this.vueAppApiUrl + "api/savepi",
        headers: {
          "Content-Type": "application/json",
          "Authorization": this.sem.authorization
        },
        data: this.pc
      }))
    },

    apiSavePrivacyPreferences() {
      // Massage the pc object that gets passed to the API (for doNotSell / pAllowSell). This is gross.
      var updatedPc = this.pc;
      updatedPc.pAllowSale = !updatedPc.doNotSell;
      delete updatedPc.doNotSell;

      return (axios({
        method: "post",
        url: this.vueAppApiUrl + "api/savepp",
        headers: {
          "Content-Type": "application/json",
          "Authorization": this.sem.authorization
        },
        data: updatedPc 
      }))
    },

    apiUpdateLastLogin() {
      return (axios({
        method: "put",
        url: this.vueAppApiUrl + "api/updatelastlogin",
        headers: {
          "Content-Type": "application/json",
          "Authorization": this.sem.authorization
        }
      }))
    },

    apiChangePassword(oldPassword, newPassword) {
      return (axios({
        method: "post",
        url: this.vueAppApiUrl + "api/changepw",
        headers: {
          "Content-Type": "application/json",
          "Authorization": this.sem.authorization
        },
        data: {
          old_password: oldPassword,
          new_password: newPassword
        }
      }))
    },

    apiGetAccount() {
      return (axios({
        method: "get",
        url: this.vueAppApiUrl + "api/account",
        headers: {
          "Content-Type": "application/json",
          "Authorization": this.sem.authorization
        }
      }))
    },

    apiLookupHouseholds(email, firstName, lastName, zip5) {
      return(        
        axios({
          method: "post",
          url: this.vueAppApiUrl + "api/lookup",
          headers: {
            "Content-Type": "application/json",
            "Authorization": this.sem.authorization
          },
          data: {
            "email": email,
            "first_name": firstName,
            "last_name": lastName,
            "zip5": zip5
          }
        })
      )
    },

    apiSelectHousehold(zip5, householdId, individualId) {
      return(
        axios({
          method: "post",
          url: this.vueAppApiUrl + "api/select",
          headers: {
            "Content-Type": "application/json",
            "Authorization": this.sem.authorization
          },
          data: {
            "zip5": zip5,
            "household_id": householdId,
            "individual_id": individualId
          }
        })
      )
    },

    apiGetProfileData(zip5) {
      return(
        axios({
          method: "get",
          url: this.vueAppApiUrl + "api/profiledata",
          headers: {
            "Content-Type": "application/json",
            "Authorization": this.sem.authorization
          },
          params: {
            "zip5": zip5
          }
        })
      )
    },

    apiGetBenchmarks() {
      return(
        axios({
          method: "get",
          url: this.vueAppApiUrl + "api/benchmarks",
          headers: {
            "Content-Type": "application/json",
            "Authorization": this.sem.authorization
          }
        })
      )
    }
  }
}
