import Vue from 'vue'
import Router from 'vue-router'
import SignUpView from './views/SignUpView.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'SignUpRoute',
      component: SignUpView
    },
    {
      path: '/about',
      name: 'AboutRoute',
      component: () => import(/* webpackChunkName: "about" */ './views/AboutView.vue')
    },
    {
      path: '/activation',
      name: 'ActivationRoute',
      component: () => import(/* webpackChunkName: "activation" */ './views/ActivationView.vue')
    },
    {
      path: '/sign-in',
      name: 'SignInRoute',
      component: () => import(/* webpackChunkName: "sign-in" */ './views/SignInView.vue')
    },
    {
      path: '/contact-us',
      name: 'ContactUsRoute',
      component: () => import(/* webpackChunkName: "contact-us" */ './views/ContactUsView.vue')
    },
    {
      path: '/request-pw-reset',
      name: 'RequestPasswordResetRoute',
      component: () => import(/* webpackChunkName: "request-password-reset" */ './views/RequestPasswordResetView.vue')
    },
    {
      path: '/reset-pw',
      name: 'ResetPasswordRoute',
      component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPasswordView.vue')
    },
    {
      path: '/error',
      name: 'ErrorRoute',
      component: () => import(/* webpackChunkName: "error" */ './views/ErrorView.vue')
    },
    {
      path: '*',
      name: 'NotFoundRoute',
      component: () => import (/* webpackChunkName: "error" */ './views/ErrorView.vue')
    },
    {
      path: "/app",
      name: "AppHomeRoute",
      component: () => import(/* webpackChunkName: "app-home" */ './views/AppView.vue'),
      children: [
        { 
          path: '/app/manage-info', 
          name: 'InformationManagementRoute',
          component: () => import(/* webpackChunkName: "manage-info" */ './views/InformationManagementView.vue'),
          redirect: {
            name: 'PersonalInformationRoute'
          },
          children: [
            {
              path: '/app/manage-info/personal-info',
              name: 'PersonalInformationRoute',
              component: () => import(/* webpackChunkName: "personal-info" */ './views/PersonalInformationView.vue')
            },
            {
              path: '/app/manage-info/privacy-prefs',
              name: 'PrivacyPreferencesRoute',
              component: () => import(/* webpackChunkName: "privacy-prefs" */ './views/PrivacyPreferencesView.vue')
            }
          ]
        },
        { 
          path: '/app/search',
          name: 'SearchRoute',
          component: () => import(/* webpackChunkName: "search" */ './views/SearchView.vue')
        },
        { 
          path: '/app/change-pw',
          name: 'ChangePasswordRoute', 
          component: () => import(/* webpackChunkName: "change-pw" */ './views/ChangePasswordView.vue') 
        },
        {
          path: '/app/profile',
          name: 'ProfileRoute',
          component: () => import(/* webpackChunk Name: "profile" */ './views/ProfileView.vue')
        },
        {
          path: '/app/*',
          name: 'AppNotFoundRoute',
          component: () => import (/* webpackChunkName: "error" */ './views/ErrorView.vue')
        }
      ]
    }
  ]
})
