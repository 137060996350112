<template>
  <div id="app" style="min-height: 100vh; position: relative">
    <header class="sticky-top shadow-sm flex-shrink-1 flex-grow-0">
      <div v-if="isLoggedIn">
        <b-navbar toggleable="md" type="light" variant="light">
          <b-navbar-brand :to="{ name: 'InformationManagementRoute' }"><img alt="Semcasting Logo" src="/images/logo-semcasting.png" width="230"></b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">            
              <b-nav-item :to="{ name: 'AboutRoute' }">About</b-nav-item>
              <b-nav-item v-if="!pcHasHousehold()" :to="{ name: 'SearchRoute' }">Search</b-nav-item>
              <b-nav-item :to="{ name: 'InformationManagementRoute' }">Manage Information</b-nav-item>
              <b-nav-item :to="{ name: 'ChangePasswordRoute' }">Password</b-nav-item>
              <b-nav-item :to="{ name: 'ContactUsRoute' }">Contact Us</b-nav-item>
              <a @click="signOut" class="nav-link">Sign Out</a>
            </b-navbar-nav>
          </b-collapse> 
        </b-navbar>
      </div>
      <div v-else>
        <b-navbar toggleable="md" type="light" variant="light">
          <b-navbar-brand :to="{ name: 'SignUpRoute' }"><img alt="Semcasting Logo" src="/images/logo-semcasting.png" width="230"></b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>  
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">            
              <b-nav-item :to="{ name: 'SignUpRoute' }">Sign Up</b-nav-item>
              <b-nav-item :to="{ name: 'AboutRoute' }">About</b-nav-item>
              <b-nav-item :to="{ name: 'ActivationRoute' }">Activate</b-nav-item>
              <b-nav-item :to="{ name: 'SignInRoute' }">Sign In</b-nav-item>
              <b-nav-item :to="{ name: 'ContactUsRoute' }">Contact Us</b-nav-item>
            </b-navbar-nav>
          </b-collapse> 
        </b-navbar>
      </div>
    </header>

    <main role="main" class="flex-shrink-0 flex-grow-1 px-sm-0 pb-5">
        <router-view/>
    </main>

    <footer id="footer" class="flex-shrink-1 flex-grow-0 bg-dark small" style="position:absolute;bottom:0;width:100%;height:3.2rem">
      <div class="container-fluid py-3">
        <div style="color:gray">
          <a href="https://www.semcasting.com/" target="_blank">&copy; 2020-2021 Semcasting Inc.</a>
          &nbsp; | &nbsp;
          <a href="mailto:privacychoice@semcasting.com">privacychoice@semcasting.com</a>
          &nbsp; | &nbsp;
          888-401-0387
          &nbsp; | &nbsp;
          <a href="https://www.semcasting.com/privacy-policy/" target="_blank">Privacy Policy</a>
          &nbsp; | &nbsp;
          <a href="mailto:privacychoice@semcasting.com">Do Not Sell</a>
        </div>
      </div>
    </footer>

  </div>
</template>

<script>
  import { pcMixin } from '@/mixins/pcMixin.js';

  export default {
    name: "PrivacyChoiceApp",

    mixins: [ pcMixin ],

    data() {
      return {
        pc: this.$store.getters.pc,
        sem: this.$store.getters.sem,
        vueAppApiUrl: process.env.VUE_APP_API_URL,
        vueAppMode: process.env.VUE_APP_MODE
      }
    },

    computed: {
      isLoggedIn() {
        // If there is an email value, then the user is logged in.
        return null != this.pc.email
      }
    }
  }
</script>
