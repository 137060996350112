/* eslint-disable no-debugger */
/* eslint-disable no-console */

import Vue from 'vue';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading, {
  container: null,
  color: '#17a2b8'
});

export const pcMixin = {

  data() {
    return {
      vueAppMode: process.env.VUE_APP_MODE,
      vueAppApiUrl: process.env.VUE_APP_API_URL,
      sem: this.$store.getters.sem,
      pc: this.$store.getters.pc,
      spinner: null
    }
  },

  methods: {

    pcSpinnerShow() {
      this.spinner = Vue.$loading.show();
    },

    pcSpinnerHide() {
      this.spinner.hide();
    },

    pcModal(content) {
      return (
        this.$bvModal.msgBoxOk(content, {
          title: 'PrivacyChoice',
          autoFocusButton: 'ok'
        })
      )
    },

    pcModalConfirm(content) {
      return (
        this.$bvModal.msgBoxConfirm(content, {
          title: 'PrivacyChoice'
        })
      )
    },

    pcHasHousehold() {
      var hhId = this.pc.householdId;
      return ((null != hhId) && (parseInt(hhId) > 0))
    },

    pcHasCoa() {
      var coa = this.pc.coa;
      return ((null != coa) && (coa != ''))
    },

    pcIsLoggedIn() {
      return null != this.pc.email;
    },

    signOut() {
      // Call the API to logout which adds the current Authentication 
      // token to the blacklist so it can't be reused.
      axios({
        method: "post",
        url: this.vueAppApiUrl + "api/logout",
        headers: {
          "Authorization": this.sem.authorization
        }
      });

      // Clear the browser.
      this.sem.authorization = null;
      this.pc.email = null;
      this.pc.firstName = null;
      this.pc.lastName = null;
      this.pc.address = null;
      this.pc.city = null;
      this.pc.state = null;
      this.pc.zip5 = null;
      this.pc.coa = null;

      // Return to the Sign In view.
      this.$router.push({ name: 'SignInRoute' })
    },

    handleError(err) {
      if (err.response.status == 403) {
        this.pcModal("Your session has expired. Please log back in.")
        .then(() => { this.signOut() })
        .catch(() => {})
      } else {
        // this.pcModal("An error occurred. Please try again later.")
        // .then((err) => { 
             console.log("err=%o", err); 
             console.log("err.response=%o", err.response); 
        // })
        // .catch(() => {})
      }
    }
  }
}
