<template>
  <div>

    <!-- Header panel -->
    <div class="panel homePanel">
      <div class="container">
        <div class="row">
          <div class="col-md-9 col-lg-6 pt-5 mt-4">
            <h3>Privacy Choice is designed to give you transparency and choice on how your data can be used in the campaigns that we support.</h3>
            <router-link :to="{ name: 'AboutRoute' }" class="btn bg-white text-info mt-4">What data do we collect...</router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- End header panel -->
    <div class="container-fluid mt-5">
      <div class="container mx-auto">
        <div class="row mb-5 pb-5 mt-5 pt-5">

          <!-- Left Column -->
          <div class="col-md-7 pr-md-5">
            <h2>Ethical use of data is important to us</h2>

            <p>The <a href="termsofservice.pdf" target="_blank">ethical use of data*</a> is important to consumers and the 
            overall economy. Internet commerce is one of the fastest growing industries in history. However, bad actors, 
            online fraud and digital theft have raised legitimate concerns about the management of personal information. 
            Supporting consumer choice while protecting user identity is critical to our future.</p>

            <p>Privacy Choice was created to:</p>

            <ul>
              <li>Provide <strong>Transparency</strong> to the information we have compiled about you,</li>
              <li>Allow you to <strong>Opt-out</strong> of any data you feel is too sensitive to share,</li>
              <li>Secure your <strong>Consent</strong> to use select data within select advertising categories,</li>
              <li>Support the choice to <strong>Do Not Sell</strong> your data going forward.</li>
            </ul>

            <!-- 2021-08-03
            <p>The selections you make in Privacy Choice will be recorded and managed by our partner 
              <a href="https://onetrust.com" target="_blank">OneTrust</a>, a recognized leader in Privacy 
              Management &amp; Governance. OneTrust also manages CCPA compliance. Your selections will be 
              permanently recorded in the Semcasting data within 10 to 30 days.</p>
            -->
            <p>Your selections will be permanently recorded in the Semcasting data within 10 to 30 days.</p>

            <p>If you have questions or suggestions on how Privacy Choice can be improved, please reach out to 
              us through our toll-free number of (888) 401-0387 or by email at:
              <a href="mailto:privacychoice@semcasting.com">privacychoice@semcasting.com</a></p>

            <div class="textbox">
              Your Choice: Opt-in, Opt-out, Select, or Do Not Sell My Personal Information. You may direct us at any
              time not to provide or sell access to your personal information. You can opt out of the use and sale of
              information to our advertisers through this privacychoice.com application, or by calling our toll free
              number (U.S. only) 888-401-0387, or by clicking <a href="mailto:privacychoice@semcasting.com">here</a>.
            </div>
            <br><br><br>
          </div>


          <!-- Right column -->
          <div class="col-md-5">
            <div class="card">
              <div class="card-body p-5">
                <h1 class="card-title mb-5 text-center">Sign Up</h1>
                <form id="signUpForm" class="text-left mw-1000" autocomplete="off" @submit.prevent="signUp">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="email" class="sr-only">Email</label>
                          <input 
                            type="email" 
                            id="email" 
                            name="email" 
                            placeholder="Email" 
                            v-model="$v.form.email.$model" 
                            maxlength="64"
                            class="form-control"
                            :class="{
                              'is-valid': !$v.form.email.$error && $v.form.email.$dirty,
                              'is-invalid': $v.form.email.$error
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="firstName" class="sr-only">First Name</label>
                          <input 
                            type="text" 
                            id="firstName" 
                            name="firstName" 
                            placeholder="First Name" 
                            v-model="$v.form.firstName.$model" 
                            maxlength="64"
                            class="form-control"
                            :class="{
                              'is-valid': !$v.form.firstName.$error && $v.form.firstName.$dirty,
                              'is-invalid': $v.form.firstName.$error
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="lastName" class="sr-only">Last Name</label>
                          <input 
                            type="text" 
                            id="lastName" 
                            name="lastName" 
                            placeholder="Last Name" 
                            v-model="$v.form.lastName.$model" 
                            maxlength="64"
                            class="form-control"
                            :class="{
                              'is-valid': !$v.form.lastName.$error && $v.form.lastName.$dirty,
                              'is-invalid': $v.form.lastName.$error
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="zip5" class="sr-only">Zip Code</label>
                          <input 
                            type="text" 
                            id="zip5" 
                            name="zip5" 
                            placeholder="Zip Code" 
                            v-model="$v.form.zip5.$model"
                            maxlength="5" 
                            class="form-control"
                            :class="{
                              'is-valid': !$v.form.zip5.$error && $v.form.zip5.$dirty,
                              'is-invalid': $v.form.zip5.$error
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <button type="submit" 
                                :disabled="$v.form.$invalid" 
                                class="btn btn-primary btn-block">Sign Up</button>
                      </div>
                  </div>
                </form>

                </div>
              </div>                    
              <div class="text-gray text-center small my-2">
                Already Registered? <router-link :to="{ name: 'SignInRoute' }">Sign In</router-link>
              </div>
              <br>
              <img class="mx-auto mb-5" src="images/banner.jpg" width="100%">
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { pcMixin } from '@/mixins/pcMixin.js';
import { apiMixin } from '@/mixins/apiMixin.js';

import { email } from 'vuelidate/lib/validators';
import { helpers } from 'vuelidate/lib/validators';
import { required } from 'vuelidate/lib/validators';

import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });

// Zip codes must be exactly 5 digits.
const zip5rule = helpers.regex('digit', /^(\d{5})?$/);

export default {

  name: "SignUpView",

  mixins: [ pcMixin, apiMixin ],

  data() {
    return {
      sem: this.$store.getters.sem,
      pc: this.$store.getters.pc,
      
      form: {
        email: null,
        firstName: null,
        lastName: null,
        zip5: null
      }
    }
  },

  validations: {
    form: {
      email: { email, required },
      firstName: { required },
      lastName: { required },
      zip5: { required, zip5rule }
    }
  },

  methods: {

    async signUp() {
      this.pcSpinnerShow();

      // Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "signup".
      const token = await this.$recaptcha('signup');

      this.apiSignup(this.form.email, this.form.firstName, this.form.lastName, this.form.zip5, token)
      .then(() => {
        this.pcModal("An email has been sent to your email address with a link and your activation code to create your account")
        .then(() => {
          // Proceed to the Activation view.
          this.$router.push({ name: 'ActivationRoute', params: { email: this.form.email } })
        })
        .catch(() => {})
      })
      .catch((response) => {
        if (response.response.status === 409) {
          this.pcModal("An account with that email already exists").catch(() => {});
        } else {
          this.pcModal("An error occurred sending the email").catch(() => {});
        }
      })
      .finally(() => {
        this.pcSpinnerHide();
      })
    }
  },

  mounted() {
    // Check here for parameters on the root URL. This is to allow external links to forward
    // to the correct route on Tomcat without having to reconfigure the web server.

    // Activation
    if (Object.prototype.hasOwnProperty.call(this.$route.query, "activation")) {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, "email")) {
        this.$router.push({ name: 'ActivationRoute', params: { email: this.$route.query.email } })
      } else {
        this.$router.push({ name: 'ActivationRoute' });
      }
    // Reset Password
    } else if (Object.prototype.hasOwnProperty.call(this.$route.query, "resetpw")) {
      this.$router.push({ name: 'ResetPasswordRoute' })
    }
    // If the user is logged in, redirect them to the Manage 
    // Information view instead of the Sign Up view.
    else if (this.pc.email) {
      this.$router.push({ name: 'PersonalInformationRoute'});
    }
  }
}
</script>
